/* eslint-disable @typescript-eslint/no-explicit-any */
/* Auto-generated file. Please don't edit it. */

interface RouteOptions {
  qs: Record<string, string>;
}

export interface RouteArguments {
  health: [RouteOptions?];
  'api-signin': [RouteOptions?];
  'api-signup': [RouteOptions?];
  robots: [RouteOptions?];
  feed: [{ slug: string }, RouteOptions?];
  'feeds-index': [RouteOptions?];
  'feed-r7': [RouteOptions?];
  home: [RouteOptions?];
  'news-index': [RouteOptions?];
  'news-show': [{ slug: string }, RouteOptions?];
  search: [RouteOptions?];
  'editorials-index': [RouteOptions?];
  'editorials-show': [{ slug: string }, RouteOptions?];
  'articles-index': [RouteOptions?];
  'api-banner': [RouteOptions?];
  'api-preferences': [RouteOptions?];
  'api-questions': [RouteOptions?];
}

const routeParamsMap = {
  health: {
    params: [],
    path: '/health'
  },
  'api-signin': {
    params: [],
    path: '/api/signin'
  },
  'api-signup': {
    params: [],
    path: '/api/signup'
  },
  robots: {
    params: [],
    path: 'robots.txt'
  },
  feed: {
    params: ['slug'],
    path: '/:slug/feed'
  },
  'feeds-index': {
    params: [],
    path: '/feeds'
  },
  'feed-r7': {
    params: [],
    path: '/feeds/r7'
  },
  home: {
    params: [],
    path: '/'
  },
  'news-index': {
    params: [],
    path: '/noticias-de-concursos'
  },
  'news-show': {
    params: ['slug'],
    path: '/n/:slug'
  },
  search: {
    params: [],
    path: '/busca'
  },
  'editorials-index': {
    params: [],
    path: '/editorias'
  },
  'editorials-show': {
    params: ['slug'],
    path: '/e/:slug'
  },
  'articles-index': {
    params: [],
    path: '/artigos'
  },
  'api-banner': {
    params: [],
    path: '/aulas-ao-vivo/api/banner'
  },
  'api-preferences': {
    params: [],
    path: '/aulas-ao-vivo/api/preferences'
  },
  'api-questions': {
    params: [],
    path: '/questions/calculate'
  }
};

export const route = <T extends keyof RouteArguments>(
  alias: T,
  ...args: RouteArguments[T]
): string => {
  const config = routeParamsMap[alias] as any;

  if (!config) {
    throw new Error('No route found for alias: ' + alias);
  }

  const hasPathParameters = config.params.length > 0;

  if (hasPathParameters && !args[0]) {
    throw new Error('Missing path parameters');
  }

  const pathParameters = hasPathParameters ? args[0] : {};
  const givenOptions: any = (
    hasPathParameters ? (args as any)[1] : args[0]
  ) as RouteArguments[T][1];
  const options = { qs: {}, ...givenOptions };
  const path = pathParameters
    ? replacePathParameters(config.path, pathParameters)
    : config.path;

  return buildPath(path, options);
};

const replacePathParameters = (
  path: string,
  params: Record<string, string>
): string => {
  return path.replace(/:([A-Za-z]+)/g, (_, key) => {
    return params[key];
  });
};

const buildPath = (
  path: string,
  { qs = {} }: { qs: Record<string, string> }
): string => {
  const search = new URLSearchParams(qs).toString();

  return search ? [path, search].join('?') : path;
};
