import { CSS, styled } from '@qcx/ui';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps
} from 'react-router';
import { forwardRef, useMemo } from 'react';

const EXTERNAL_LINK_PREFIXES = ['http', 'mailto:', 'tel:', 'file:'];

export interface LinkProps extends ReactRouterLinkProps {
  css?: CSS;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, ...props }, ref) => {
    const isExternal = useMemo(
      () =>
        EXTERNAL_LINK_PREFIXES.some(
          prefix => typeof to === 'string' && to.startsWith(prefix)
        ),
      [to]
    );

    const href = useMemo(() => `${to}`, [to]);

    return isExternal ? (
      <StyledNativeLink ref={ref} {...props} href={href} />
    ) : (
      <StyledLink ref={ref} {...props} to={href} />
    );
  }
);

const StyledLink = styled(ReactRouterLink);
const StyledNativeLink = styled('a');

Link.displayName = 'Link';
